import React from 'react';
import Layout from '../components/Layout';
import socialLinks from "../content/social";
import SocialIcon from "../components/SocialIcon";

const SocialPage = () => (
  <Layout>
    <div className="social-wrapper">
      {/* <a href={'mailto:' + content.email}>{content.email}</a> */}
      <a target="_blank" rel="noopener noreferrer" href={socialLinks.twitter}><SocialIcon type="twitter"/></a>
      <a target="_blank" rel="noopener noreferrer" href={socialLinks.facebook}><SocialIcon type="facebook"/></a>
      <a target="_blank" rel="noopener noreferrer" href={socialLinks.instagram}><SocialIcon type="instagram"/></a>
    </div>
  </Layout>
);

export default SocialPage;
