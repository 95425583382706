import React from 'react';

import "./styles/social.css"
import facebookIcon from "../img/facebook.svg";
import twitterIcon from "../img/twitter.svg";
import instagramIcon from "../img/instagram.svg"

const typeToSrc = {
    facebook: facebookIcon,
    twitter: twitterIcon,
    instagram: instagramIcon
}

const SocialIcon = ({type}) => (
    <img className="social-icon" src={typeToSrc[type]} alt={type + " icon"}/>
);

export default SocialIcon;